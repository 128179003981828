<template>
  <el-dialog
    :title="$t('errors.please_login')"
    :visible.sync="isVisible"
    class="followCompanyModal"
    width="30%"
  >
    <p>
      {{ $t('errors.you_need_to_login_or_register_to_follow') }}
    </p>
    <div class="buttons is-centered m-t-m">
      <button class="button is-primary" @click="goToJournalistsRegister">
        {{ $t('forms.register') }}
      </button>
      <button class="button is-primary" @click="goToLogin">
        {{ $t('components.login.journalist_login') }}
      </button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  computed: {
    isVisible: {
      get () {
        return this.$store.state.followNewsroomNeedsLoginModal
      },
      set (value) {
        this.$store.commit('TOGGLE_FOLLOW_NEWSROOM_MODAL', value)
      }
    }
  },
  methods: {
    goToJournalistsRegister () {
      if (this.isLoggedIn) return this.$notify.warning(this.$t('components.follow_company_needs_login.logout_before_register_as_journalist'))
      this.isVisible = false
      this.$router.push(this.$i18nRoute({ name: 'JournalistRegister' }))
    },
    goToLogin () {
      if (this.isLoggedIn) return this.$notify.warning(this.$t('components.follow_company_needs_login.logout_before_login_as_journalist'))
      this.isVisible = false
      this.$router.push({ name: 'login', query: { redirect: this.$route.fullPath } })
    }
  }
}
</script>
